.panel-content-add-item {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
  margin-bottom: 30px;

  &__items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__item {

      &__button {
        display: flex;
        justify-content: flex-end;
      }

      &--add {
        flex: 1;
      }

      &--status,
      &--advanced {
        min-width: 200px;
        padding-left: 15px;
      }

      &__label {
        position: relative;
        top: -1px;

        &__icon {
          display: inline-block;
          padding: 0 10px 0 13px  ;
          position: relative;
          top: -2px;
        }

        &__title {
          font-weight: bold;
        }
      }
    }
  }
}
