.password-recovery {
  &__form {
    display: flex;
    height: 100vh;
    justify-content: center;
    flex-direction: column;

    &__inner {
      &__logo {
        display: block;
        margin: 30px auto;
      }

      &__message {
        text-align: center;

        &__title {
          display: block;
          padding: 15px 0;
          color: @primary-color;
        }

        &__warning {
          text-align: center;
          padding-bottom: 15px;
          color: @primary-color;
        }
      }

      &__button {
        padding-top: 60px;
      }

      &__bottom {
        text-align: center;
        padding-top: 30px;
      }
    }
  }
}
