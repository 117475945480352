.panel-content {
  padding: 15px !important;
  background-color: @gray-bg;
  display: flex;
  flex: 1;
  flex-direction: column;

  &__inner {
    background-color: white;
    padding: 30px;
  }
}
