.advanced-select {
  padding-bottom: 15px;

  .MuiSelect-select.Mui-disabled {
    cursor: pointer !important;
  }

  .MuiFormControl-root {
    margin: 0 !important;
  }

  .MuiInputLabel-outlined {
    background-color: white;
    padding: 1px 6px;
    left: -4px;
  }
}
