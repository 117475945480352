.panel-sidebar {
  width: 240px;
  border-right: 1px solid @gray-border;

  &__logo {
    padding: 30px;

    &__img {
      max-width: 65%;
      margin: 0 auto;
      display: flex;
    }
  }
}
