.advanced-radio {
  padding-bottom: 15px;

  &__label {
    padding-top: 15px;
  }

  .MuiFormControlLabel-root {
    display: inline-block;
  }

  .MuiFormControlLabel-label {
    position: relative !important;
    top: 2px;
  }
}
