.loading {
  width: 100vw;
  height: 100vh;
  background-color: rgba(255,255,255,0.9);
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 500;
  top: 0;
  left: 0;

  &__inner {
    width: 150px;
    align-self: center;

    &__img {
      max-width: 100%;
    }
  }
}
