.my-synod-form {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  &__items {
    width: 100%;
    &__item {
      &--add {
        flex: 1;
      }

      &__button {
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}
