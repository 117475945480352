.advanced-file-upload {
  display: flex;
  width: 100%;
  flex-direction: row;

  &__preview-container {
    display: flex;
    flex-direction: row;
  }

  &__preview {
    display: block;
    width: 175px;
    height: 175px;
    padding: 10px 5px;
    margin-top: -5px;

    &__img {
      width: 100%;
      height: 100%;
    }
  }

  &__upload {
    display: block;
    width: 100%;

    .ant-upload-list {
      display: none !important;
    }

    .ant-upload {
      display: block;
      width: 100%;
      position: relative;
    }

    &__button {
      position: absolute;
      top: 25px;
      right: 0;

      .ant-btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }

      &--remove {
        top: 26px;
        right: 8px;

        .ant-btn {
          small {
            position: relative;
            top: -1px;
          }
        }
      }
    }
  }
}
