.advanced-button {

  &__inner {
    display: flex;
    flex: 1;
    justify-content: space-between;

    &__start-icon,
    &__end-icon {
      width: 25px;
      height: 15px;
      display: inline-block;
    }

    &__start-icon {
      padding-right: 15px;
    }

    &__end-icon {
      padding-left: 15px;
    }

    &__text {
      align-self: center;
    }
  }
}
