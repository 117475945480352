.institutional-form {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  &__items {
    width: 100%;
    &__item {
      &__button {
        display: flex;
        justify-content: flex-end;
      }

      &--add {
        flex: 1;
      }

      &--status,
      &--advanced {
        min-width: 200px;
        padding-left: 15px;
      }

      &__label {
        position: relative;
        top: -1px;

        &__icon {
          display: inline-block;
          padding: 0 10px 0 13px;
          position: relative;
          top: -2px;
        }

        &__title {
          font-weight: bold;
        }
      }
    }
  }

  .ant-collapse.ant-collapse-icon-position-left {
    margin: 10px 0;
  }
}
