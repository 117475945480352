.kpi-bar {

  &__inner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__info {

      &__number {
        font-size: 13pt;
        font-weight: bold;
      }

      &__description {
        font-size: 11pt;
        opacity: 47%;
      }
    }

    &__percentage {
      color: @primary-color;
      font-weight: bold;
      font-size: 14pt;
    }
  }

  &__bar {
    width: 100%;
    height: 20px;
    background-color: @card-bg;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    margin-top: 15px;

    &__inner {
      background-color: @primary-color;
      width: 0;
      height: 20px;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}
