.not-found {
  padding-top: 15vh;

  &__inner {
    text-align: center;

    &__icon {

      &__img {
        width: 100px;
      }
    }

    &__title {
      font-size: 30pt;
      text-transform: uppercase;
      padding-top: 15px;
    }

    &__desc {
      white-space: pre-wrap;
    }
  }
}
