.kpi-card {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: @card-bg;
  border-radius: 5px;
  padding: 12px 10px 10px;

  &__icon {
    min-width: 65px;
    text-align: center;
    padding-top: 8px;
  }

  &__right {

    &__number {
      font-size: 13pt;
      font-weight: bold;
    }

    &__description {
      font-size: 10pt;
      opacity: 47%;
    }
  }
}
