.notifications {
  width: 100%;
  height: 100%;

  &__advanced-filters {
    
    &__item {
      
      &--button {
        padding-top: 15px;
      }
    }
  }

  &__table {
    height: 680px;
  }

  &__label {
    margin: 0 20px 0;
  }

  .row {
    margin: 0;
    margin-right: 0;
    margin-left: 0;
  }

  .col-lg-6 {
    padding-left: 0;
    padding-right: 0;
  }

  .ant-collapse-header {
    color: @primary-color!important;
    font-size: 18px;
  }

  &__subtitle {
    margin: 0 0 10px;
    font-size: 18px;
    color: @primary-color;
  }

  &__space {
    margin: 20px 0;
  }
}
